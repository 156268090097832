.tooltip-card {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  overflow: hidden;
}

.tooltip-card-content {
  padding: 16px;
  padding-bottom: 24px;
}

.tooltip-title {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
  letter-spacing: 0.00714em;
  margin-bottom: 16px;
}

.tooltip-label-marker {
  height: 8px;
  width: 8px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 12px;
}

.tooltip-label-text {
  left: 4px;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.tooltip-label-number {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.tooltip-label-text-secondary {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  max-width: 240px;
  word-wrap: break-word;
  white-space: normal;
}

.tooltip-divider {
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-bottom-width: thin;
  margin: 16px 0px;
}

.tooltip-img {
  width: 90px;
  height: 60px;
  margin: 6px;
}

.apexcharts-tooltip {
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}
