.App {
  text-align: left;
}

.main-page-content {
  height: calc(100vh - 56px);
  overflow-y: scroll;
  padding: 0;
}
@media (min-width: 0px) and (orientation: landscape) {
  .main-page-content {
    height: calc(100vh - 48px);
  }
}
@media (min-width: 600px) {
  .main-page-content {
    height: calc(100vh - 64px);
  }
}
.main-page-sticky-header {
  display: flex;
  padding: 24px 32px;
}
@media (max-width: 900px) {
  .main-page-sticky-header {
    padding: 16px 24px;
  }
}
.main-page-sticky-header.with-button {
  justify-content: space-between;
}
.main-page-sticky-header-wrapper {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
}
.main-page-body {
  width: 100%;
  max-width: 960px;
  padding: 8px 24px 32px 24px;
}
.main-page-body-wrapper {
  display: flex;
  justify-content: center;
}
.main-page-fab {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.navBar {
  background-color: #ffcc00;
}
.btnGrey {
  background-color: #bcbcbb;
}
.btnBlue {
  background-color: #00a6e1;
}
.btnGreen {
  background-color: #5dd41b;
}
.btnRed {
  background-color: #df462e;
}

.fontSizeSmall {
  font-size: 90% !important;
}
.fontSizeDefault {
  font-size: 100% !important;
}
.fontSizeLarge {
  font-size: 120% !important;
}
.fontSizeLargest {
  font-size: 140% !important;
}

.typography-wrap {
  white-space: pre-line;
}

.record-card-content {
  padding: 24px 24px 16px 24px;
}
.record-card-icon {
  padding-left: 0px;
}

.MuiCardActions-root {
  padding: 8px 16px;
}

.number-card {
  border-radius: 16px;
  padding: 16px 0px 8px 0px;
}
.number-card-content {
  align-items: center;
}
.number-card-content-numeral {
  font-weight: 700;
}
.number-card-content-text {
  font-weight: 600;
}

.dialog-header {
  align-items: center;
  justify-content: space-between;
}
.dialog-header-button {
  align-items: center;
}
.dialog-header-button.left {
  padding-left: 24px;
}
.dialog-header-button.left-empty {
  padding-left: 48px;
}
.dialog-header-button.right {
  padding-right: 24px;
}
