.MuiBanner-cardContentIconAppBar {
  padding-top: 8px !important;
}

.review-page-body {
  height: calc(100% - 128px);
}
.review-page-body-wrapper {
  height: 100%;
  position: relative;
}
.review-page-decision-button {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 40px 32px;
  position: absolute;
  width: 100%;
}
@media (max-width: 900px) {
  .review-page-decision-button {
    padding: 32px 24px;
  }
}
